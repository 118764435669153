import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PrivacyPolicyPage = () => (
	<Layout>
		<SEO title="Privacy Policy" />
		<div className="container p-4 text-brand-grayt">
			<h1 className="text-3xl uppercase mb-8">
				<Trans>Privacy Policy</Trans>
			</h1>
			<p>
				<Trans i18nKey="DGITAL Labs understand that the customers privacy">
					DGITAL Labs understands that the customer’s privacy is important. In respect of the privacy of the
					Firm’s contacts, personal data will be collected and used only in ways described in this document.
				</Trans>
			</p>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Purpose</Trans>
			</h2>
			<p>
				<Trans i18nKey="The purpose of this policy">
					The purpose of this policy is to ensure that the provided data will be stored and handled fairly,
					transparently and with respect towards individual rights.
				</Trans>
			</p>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Audience</Trans>
			</h2>
			<p>
				<Trans i18nKey="This policy refers to employees">
					This policy refers to employees, contractors, consultants, temporary employees, and all other
					workers at DGITAL, including all personnel affiliated with third parties who provide personally
					identifiable information to the Firm.
				</Trans>
			</p>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Information About Us</Trans>
			</h2>
			<p>
				<Trans i18nKey="DGITAL Labs Ltd is a limited company">
					DGITAL Labs Ltd is a limited company registered address at 1103 Budapest, Kőér utca 2/A, Building
					„A”, 1st floor, e-mail:
				</Trans>{" "}
				<a className="text-brand-green" href="mailto:info@dgital.com">
					info@dgital.com
				</a>
				, telephone: +36 (1) 550 7500, website:{" "}
				<a className="text-brand-green" href="https://dgital.com/">
					dgital.com
				</a>
				.
			</p>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Your rights</Trans>
			</h2>
			<p className="mb-2">
				<Trans i18nKey="Anyone providing personal informartion">
					Anyone providing personal information to DGITAL Labs has the rights according to GDPR:
				</Trans>
			</p>
			<ul className="list-disc ml-8">
				<li>
					<Trans i18nKey="Right of access">
						Right of access: You have the right to obtain confirmation as to whether or not your personal
						data is being processed by us, and, where that is the case, to request access to the personal
						data.
					</Trans>
				</li>
				<li>
					<Trans i18nKey="Right to rectification">
						Right to rectification: In certain cases, you have the right to request the rectification of
						inaccurate personal data, or the completion of incomplete personal data.
					</Trans>
				</li>
				<li>
					<Trans i18nKey="Right to erasure">
						Right to erasure (right to be forgotten): In certain cases, you have the right to request the
						erasure of your personal data, and we may be obliged to erase such personal data.
					</Trans>
				</li>
				<li>
					<Trans i18nKey="Right to restriction">
						Right to restriction of processing: In certain cases, you have the right to request the
						restriction of the processing of your personal data. In this case, the respective personal data
						may be processed only for certain purposes.
					</Trans>
				</li>
				<li>
					<Trans i18nKey="Right to portability">
						Right to data portability: In certain cases, you have the right to receive the personal data you
						have provided to us in a structured, commonly used and machine-readable format and have the
						right to transmit those data to another controller without any hindrance from us.
					</Trans>
				</li>
				<li>
					<Trans i18nKey="Right to object">
						Right to object: In certain cases, you have the right to object to the processing of your
						personal data, and we may be required to cease the processing of such data.
					</Trans>
				</li>
			</ul>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Personally identifiable information we obtain</Trans>
			</h2>
			<p className="mb-2">
				<Trans i18nKey="Personal data is defined by GDPR">
					Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the
					“GDPR”) as ‘any information relating to an identifiable person who can be directly or indirectly
					identified in particular by reference to an identifier’.
				</Trans>
			</p>
			<p>
				<Trans i18nKey="When contacting DGITAL">
					When contacting DGITAL Labs via e-mail or other communication platform, the following personal
					information may be requested:
				</Trans>
			</p>
			<ul className="list-disc ml-8">
				<li>
					<Trans>Name</Trans>
				</li>
				<li>
					<Trans>E-mail address and/or telephone number</Trans>
				</li>
				<li>
					<Trans>Company name and title</Trans>
				</li>
				<li>
					<Trans>The reason of contact</Trans>
				</li>
			</ul>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Use of your personal data</Trans>
			</h2>
			<p className="mb-2">
				<Trans i18nKey="The purposes for which">The purposes for which we use your personal data are:</Trans>
			</p>
			<ol className="list-decimal ml-8">
				<li>
					<Trans>
						for direct marketing purposes to provide services and offers tailored to your requirements;
					</Trans>
				</li>
				<li>
					<Trans i18nKey="to communicate with you">
						to communicate with you (for instance to send you reminder emails, or notifications about any
						change) (we use for this purpose personal data such as name and contact details);
					</Trans>
				</li>
			</ol>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Legal basis of data processing</Trans>
			</h2>
			<p className="mb-2">
				<Trans i18nKey="The processing of your personal data">
					The processing of your personal data is based on the following legal basis:
				</Trans>
			</p>
			<ul className="list-disc ml-8 mb-2">
				<li>
					<Trans i18nKey="processing of your personal data">
						the processing of your personal data for the purposes listed under section Use of your personal
						data: points 2 is necessary for the performance of the contract between us, or to perform the
						pre-contractual steps request by you (under Art. 6 para 1 b) of the GDPR);
					</Trans>
				</li>
				<li>
					<Trans i18nKey="the processing of your personal data for the">
						the processing of your personal data for the purposes listed under section Use of your personal
						data: point 1 is based on your consent (under Art. 6 para 1 a) of the GDPR).
					</Trans>
				</li>
			</ul>
			<p className="mb-2">
				<Trans i18nKey="in addition to the above">
					In addition to the above, the legal basis of the processing of your sensitive data is your explicit
					consent (under Art. 9 para 1 a) of the GDPR).
				</Trans>
			</p>
			<p className="mb-2">
				<Trans i18nKey="The provision of your data is voluntary however">
					The provision of your data is voluntary. However, if you do not provide your personal data, it may
					mean that we will not be able to provide all or parts of the services you have requested.
				</Trans>{" "}
			</p>
			<p className="mb-2">
				<Trans i18nKey="In case of the processing">
					In case of the processing of your data based on your consent for direct marketing purposes
					(including profiling), you are entitled to freely withdraw your consent anytime.
				</Trans>
			</p>
			<h2 className="text-xl font-semibold py-4">
				<Trans>Disclosure of your personal data to third parties</Trans>
			</h2>
			<p className="mb-2">
				<Trans i18nKey="We do not share your personal data">
					We do not share your personal data with third party marketing or communications agencies.
				</Trans>
			</p>
			<p className="mb-2">
				<Trans i18nKey="in some circumstances we may share">
					In some circumstances, we may share your personal data with the below third parties:
				</Trans>
			</p>
			<ul className="list-disc ml-8">
				<li>
					<Trans>
						Our professional advisors such as our auditors and external legal and financial advisors
					</Trans>
				</li>
				<li>
					<Trans>Business partners and sub-contractors</Trans>
				</li>
			</ul>
			<h2 className="text-xl font-semibold py-4">Data retention</h2>
			<p className="mb-2">
				<Trans i18nKey="We process your personal data">
					We process your personal data as long as it is necessary to achieve the purpose for which it was
					collected. In general, the following retention periods would apply:
				</Trans>
			</p>
			<ul className="list-disc ml-8">
				<li>
					<Trans i18nKey="except for the data contained in documents">
						except for the data contained in documents necessary for accounting purposes, the data collected
						for the purposes listed under section Use of your personal data: points 2 will be retained for 5
						years following the performance of the contract between us;
					</Trans>
				</li>
				<li>
					<Trans i18nKey="documents necessary for accounting purposes">
						documents necessary for accounting purposes (and the data contained therein) will be stored for
						8 years from the closing of the relevant financial year;
					</Trans>
				</li>
				<li>
					<Trans i18nKey="the data collected for the purposes listed under">
						the data collected for the purposes listed under section Use of your personal data: point 2 will
						be retained until you have withdrawn your consent to the processing.
					</Trans>
				</li>
			</ul>
		</div>
	</Layout>
);

export default PrivacyPolicyPage;
